<template>
  <el-form @submit.prevent="submit()" :model="form" ref="formRef" class="form">
    <div>
      <label class="fs-6 fw-bold mb-2">
        <span>保留原因</span>
      </label>
      <el-form-item>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2 }"
          placeholder="請輸入保留原因"
          v-model="form.reason"
        >
        </el-input>
      </el-form-item>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-success" type="submit">送出</button>
    </div>
  </el-form>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { updateTokenRemark } from "@/core/services/api/tokens";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  props: ["currentReserve"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const form = reactive({
      reason: "",
    });

    const submit = async () => {
      await Swal.fire({
        title: "您確定要修改嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let request = {
            token: props.currentReserve.guid,
            remark: form.reason,
          };
          await updateTokenRemark(request)
          await Swal.fire("修改成功!", "", "success");
          emit("onModifyReason")
        }
      });
    };

    const setFormData = () => {
      Object.assign(form, {
        reason: props.currentReserve.remark,
      });
    };

    watch(
      props,
      () => {
        setFormData();
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      formRef,
      form,
      submit,
    };
  },
});
</script>

<style>
</style>