<template>
  <div class="card">
    <div
      class="
        card-header
        border-0
        pt-6
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <h1>{{ facilityData.name }}</h1>
      <button type="button" class="btn btn-success" @click="toAddReserve()">
        特殊保留
      </button>
    </div>
    <div class="card-body pt-0">
      <div v-if="isLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-facilityUnitName="{ row: tableData }">
          {{ tableData.facilityUnitName }}
        </template>
        <template v-slot:cell-tableBeginTime="{ row: tableData }">
          {{ tableData.tableBeginTime }}
        </template>
        <template v-slot:cell-tableEndTime="{ row: tableData }">
          {{ tableData.tableEndTime }}
        </template>
        <template v-slot:cell-remark="{ row: tableData }">
          {{ tableData.remark }}
        </template>
        <template v-slot:cell-operate="{ row: tableData }">
          <el-dropdown>
            <span
              @mouseover="setCurrentReserve(tableData)"
              class="el-dropdown-link text-primary"
            >
              功能選單<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  data-bs-toggle="modal"
                  data-bs-target="#modify-reason-modal"
                  >修改保留原因</el-dropdown-item
                >
                <el-dropdown-item @click="releaseReservation()"
                  >釋放</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </Datatable>
    </div>
  </div>
  <!-- 修改保留原因modal -->
  <div
    class="modal fade"
    id="modify-reason-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modify-reason-exampleModalLabel">
            修改保留原因
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ModifyReason
            :currentReserve="currentReserve"
            @onModifyReason="onModifyReason()"
          ></ModifyReason>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import {
  getSpecialReserve,
  rempveSpecialReserve,
} from "@/core/services/api/tokens";
import { getFacilityUnitByFacility } from "@/core/services/api/facilityUnits";
import { useRouter, useRoute } from "vue-router";
import localstorage from "@/core/services/LocalstorageService";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getFacility } from "@/core/services/api/facilities";
import ModifyReason from "./components/ModifyReason.vue";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
    ModifyReason,
  },
  emits: ["onModifyReason"],
  setup() {
    let modifyReasonModal;
    const currentReserve: any = reactive({});
    const facilityData: any = reactive({});
    const tableHeader = ref([
      { key: "facilityUnitName", name: "座位名稱", sortable: true },
      { key: "tableBeginTime", name: "開始時間", sortable: true },
      { key: "tableEndTime", name: "結束時間", sortable: true },
      { key: "remark", name: "保留原因", sortable: true },
      { key: "operate", name: "操作" },
    ]);
    const tableData: any = reactive([]);
    const route = useRoute();
    const router = useRouter();
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const facilityGuid: any = route.params.facilityGuid;
    const facilityUnits: any = reactive([]);
    const isLoading = ref(false);

    // 取得設施所有座位
    const handleSetFacilityUnits = async () => {
      let response: any = await getFacilityUnitByFacility(
        currentSpaceGuid!,
        facilityGuid
      );
      facilityUnits.splice(0, facilityUnits.length, ...response);
    };

    // 取得設施特殊保留
    const getFacilitySpecialReserve = async () => {
      let requestBody = {
        facility: facilityGuid,
        facilityUnit: "",
      };
      return await getSpecialReserve(requestBody);
    };

    // 根據座位guid取得資料
    const getFacilityUnitByGuid = (guid) => {
      return facilityUnits.filter((o) => o.guid == guid)[0];
    };

    const setTableData = async () => {
      isLoading.value = true;
      let specialReserve: any = await getFacilitySpecialReserve();
      for (const reserve of specialReserve) {
        reserve.facilityUnitName =
          reserve.facilityUnit == ""
            ? "未指定"
            : getFacilityUnitByGuid(reserve.facilityUnit).name;
        reserve.tableBeginTime = getLocalTimeString(reserve.beginTime);
        reserve.tableEndTime = getLocalTimeString(reserve.endTime);
      }
      tableData.splice(0, tableData.length, ...specialReserve);
      isLoading.value = false;
    };

    const setFacilityData = async () => {
      let response: any = await getFacility(facilityGuid);
      Object.assign(facilityData, response.facility);
    };

    const toAddReserve = () => {
      router.push({
        path: `/basicSettings/facilityManagement/addReserve/${facilityGuid}`,
      });
    };

    const setCurrentReserve = (item) => {
      Object.assign(currentReserve, item);
    };

    const onModifyReason = async () => {
      await setTableData();
      modifyReasonModal.hide();
    };

    const releaseReservation = async () => {
      await Swal.fire({
        title: "您確定要釋放嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let request = {
            tokens: [currentReserve.guid],
          };
          await Swal.fire("釋放成功!", "", "success");
          await rempveSpecialReserve(request);
        }
      });
    };

    onMounted(() => {
      modifyReasonModal = new Modal(
        document.getElementById("modify-reason-modal")
      );
    });

    const init = async () => {
      await handleSetFacilityUnits();
      await setTableData();
      await setFacilityData();
    };
    init();

    return {
      tableHeader,
      tableData,
      facilityData,
      toAddReserve,
      setCurrentReserve,
      currentReserve,
      onModifyReason,
      releaseReservation,
    };
  },
});
</script>

<style>
</style>
